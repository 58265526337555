import {
  ASK_ASSISTANT,
  ASSISTANT_RESPONDED,
  CLEAR_CHAT,
  IAssistantActions,
  SET_CHAT_WINDOW_VISIBILITY,
} from '../actions/co-pilot.actions';
import {
  ICoPilotConversation,
  ICoPilotMessages,
  ICoPilotState,
} from '../state/co-pilot.state.model';

const defaultState: ICoPilotState = {
  conversation: {
    data: {
      messages: [],
      totalMessages: 0,
    },
    status: 'loaded',
    windowOpened: false,
  },
};

const newState = (state: ICoPilotState, newData: Partial<ICoPilotState>) => {
  return Object.assign({}, state, newData);
};

export function coPilotReducer(
  state: ICoPilotState = defaultState,
  action: IAssistantActions
) {
  switch (action.type) {
    case ASK_ASSISTANT: {
      const newMessages = [
        ...state.conversation.data.messages,
        action.payload,
      ] as ICoPilotMessages;
      return newState(state, {
        conversation: {
          ...state.conversation,
          data: { messages: newMessages, totalMessages: newMessages.length },
          status: 'loading',
        },
      });
    }
    case ASSISTANT_RESPONDED:
      return newState(state, {
        conversation: {
          ...state.conversation,
          data: action.payload as ICoPilotConversation,
          status: 'loaded',
        },
      });
    case CLEAR_CHAT:
      return newState(state, {
        conversation: {
          ...state.conversation,
          data: { totalMessages: 0, messages: [] } as ICoPilotConversation,
          status: 'loaded',
        },
      });
    case SET_CHAT_WINDOW_VISIBILITY:
      return newState(state, {
        conversation: {
          ...state.conversation,
          windowOpened: action.payload as boolean,
        },
      });

    default:
      return state;
  }
}
